import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api-service/api.service';
import { CommonApiService } from 'src/app/services/common-api-modules/common-api.service';
import { SessionService } from 'src/app/session-service/session.service';
import { API_ENDPOINTS_DISTRIBUTOR } from 'src/assets/data-variable/api-constant';
// import { OmanInsuranceService } from '../service/oman-insurance.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  show = false;
  confirmPasswordShow=false;
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  insurerUrl: any;
  role: any;
  userId: any;
  constructor(
    private toster:ToastrService,
    private router:Router,  public fb: FormBuilder,  private modalService: NgbModal,private apiService:ApiService,private sessionService:SessionService,private commonApiService : CommonApiService) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      current_password: ['',[Validators.required]],
      new_password: ['',[Validators.required,Validators.minLength(5)]],
    });
   
  }

  getSessionData() {
    this.insurerUrl = this.sessionService.get("insurerURL");
    this.role = this.sessionService.get("role");
    this.userId = this.sessionService.get("user_id");
  }

  get formControls() { 
    return this.changePasswordForm.controls
  }

  async confirmPassword(){
    try{
      this.getSessionData();
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    }
    const params = {
      userId : this.userId,
      oldPassword : this.changePasswordForm.value.current_password,
      newPassword : this.changePasswordForm.value.new_password,
    }
    let results = await this.commonApiService.changePassword(params)
    if(!results){
      this.toster.error("Something went wrong");
      return;
    }
    this.toster.success("Password updated successfully")
    if(this.role == 'reinsurer'){
      this.router.navigateByUrl(`/`);
    }else{
      this.router.navigateByUrl(`/${this.insurerUrl}/login`);
    }
    this.modalService.dismissAll()

  }catch(error){
    if(error.response.status == 400){
      this.toster.error(error.response.data.message)
      return
    }else if(error.response.status == 401){
      this.toster.error(error.response.data.message)
      return
    }
  }
  }

  closePasswordPopup(){
    this.modalService.dismissAll()
  }
}
