import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { TwoFactorAuthComponent } from '../two-factor-auth/two-factor-auth.component';
import { TwoFactorValidateComponent } from '../two-factor-validate/two-factor-validate.component';
import { MfaGuard } from './auth-service/mfa.guard';

const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'2fa',
    component:TwoFactorAuthComponent,
    canActivate:[MfaGuard]
  },
  {
    path:'2fv',
    component:TwoFactorValidateComponent,
    canActivate:[MfaGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
