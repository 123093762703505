import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthServiceService } from '../authentication/auth-service/auth-service.service'; 
import { Router } from '@angular/router';
import { SessionService } from 'src/app/session-service/session.service';
import { ToastrService } from 'ngx-toastr';
import { TOKEN } from 'src/assets/data-variable/session-variables';
import { ApiService } from 'src/app/api-service/api.service';
import { API_ENDPOINTS_DISTRIBUTOR } from 'src/assets/data-variable/api-constant';
import { PermissionServiceService } from 'src/app/permissions/permission-service.service';

@Component({
  selector: 'app-two-factor-validate',
  templateUrl: './two-factor-validate.component.html',
  styleUrls: ['./two-factor-validate.component.scss']
})
export class TwoFactorValidateComponent implements OnInit {

   is2FaEnabled = false
   failed_count=0;
   insurerName: any = "";
   isLoading = false


  @ViewChild('input_1') input1!: ElementRef<HTMLInputElement>;
  @ViewChild('input_2') input2!: ElementRef<HTMLInputElement>;
  @ViewChild('input_3') input3!: ElementRef<HTMLInputElement>;
  @ViewChild('input_4') input4!: ElementRef<HTMLInputElement>;
  @ViewChild('input_5') input5!: ElementRef<HTMLInputElement>;
  @ViewChild('input_6') input6!: ElementRef<HTMLInputElement>;



  constructor(
    private router :Router,
    private sessionService:SessionService,
    private toaster:ToastrService,
    private apiService: ApiService,
    private permissions:PermissionServiceService
  ) { }

  ngOnInit(): void {
  }

  handleInput(
    previousInput: ElementRef<HTMLInputElement>,
    currentInput: ElementRef<HTMLInputElement>,
    nextInput: ElementRef<HTMLInputElement>,
    event: KeyboardEvent
  ) {

    const allowedKeyArray = ['Backspace', 'Tab', 'Shift']

    if (!(event.code.startsWith('Digit') || event.code.startsWith('Numpad')) && !allowedKeyArray.includes(event.key)) { 
      event.preventDefault()
      return
    }

    if (previousInput && event.key === allowedKeyArray[0]) {
      currentInput.nativeElement.value = ""
      previousInput.nativeElement.focus()
      return
    }

    if (event.key === allowedKeyArray[0]) return

    if (currentInput.nativeElement.value && nextInput) {
      nextInput.nativeElement.focus();
    }
  }

  disableAuthenticationButton(){
    if(!this.input1?.nativeElement.value ||
       !this.input2?.nativeElement.value || 
       !this.input3?.nativeElement.value || 
       !this.input4?.nativeElement.value || 
       !this.input5?.nativeElement.value ||
       !this.input6?.nativeElement.value ){
      return true
    }
    return false
  }

  async mfaVerify(mfaOtp:string){
    try {
      this.isLoading = true
      let results = await this.apiService.postApiCall('auth/2fa/validate',{mfaOtp})

      if (results.statusCode==200)
        {
          this.failed_count=0;
        }
        if (!results) {
          this.toaster.error("Something went wrong");
          return;
        }

      const permissionsResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getAllPermissions}/${results.response.role}`, {});
        this.permissions.userPermissions= permissionsResponse.response;
        this.sessionService.set('permissions',JSON.stringify(permissionsResponse.response));

      if (results.response.role === "distributor") {
        this.router.navigateByUrl(`${results.response.insurerURL}/distributor-v1/main`);
      } else if (results.response.role === "customer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/customer-v1/home`);
      } else if (results.response.role === "reinsurer") {
        this.router.navigateByUrl(`${results.response.insurerURL}/reinsurer-v1`);
      } else if (results.response.role === "insurer" || results.response.role === "underwriter"  || results.response.role === "salesaccess"  || results.response.role === "operational")  {
        this.router.navigateByUrl(`${results.response.insurerURL}/${results.response.role}-v1/dashboard`);
      }

      this.sessionService.set(TOKEN, results.response.token);
      this.sessionService.set('userVerified', results.response.userVerified);
      this.sessionService.set("role", results.response.role);
      this.sessionService.set("user_id", results.response.userId);
      this.sessionService.set("insurerURL", results.response.insurerURL);
      this.sessionService.set("brokerCompanyId", results.response.broker_company_id);
      this.sessionService.set("reinsurerName", results.response.reInsurerUrl);
      this.sessionService.set("insurerName", this.insurerName);
      this.sessionService.set("reinsurerShortForm", results.response.insurerShortForm);
      this.toaster.success("Logged in successfully");
    } catch (error) {
      //Error handling will be done after the backend configuration has been finished
      this.toaster.error(error.message);

    }
    finally{
      this.isLoading = false
    }
  }

  validate(){
    const mfaOtp = this.input1.nativeElement.value + 
    this.input2.nativeElement.value +
    this.input3.nativeElement.value +
    this.input4.nativeElement.value +
    this.input5.nativeElement.value +
    this.input6.nativeElement.value

    this.mfaVerify(mfaOtp)
  }

}