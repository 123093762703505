<div class="two-factor-container">
  <div class="two-factor-child">
    <!-- <div class="toggle-container mb-4">
      <h2 class="title">Enable Two-Factor Authentication</h2>
      <div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [checked]="is2FaEnabled">
        </div>
      </div>
    </div> -->
    <div>
      <!-- <div class="mb-2">
        <h2 class="title">QR Code</h2>
        <p class="sub-title">Scan this code using two-factor authentication app on your mobile device, such as Google Authenticator or Microsoft Authenticator.</p>
      </div>

      <div class="qr-code mb-4">
        <img src="" alt="qr code">
      </div> -->

      <div>
        <h2 class="title">Verification Code<span>*</span></h2>
        <p class="sub-title">Generated by two-factor authentication app on your mobile device</p>
      </div>

      <div class="d-flex gap-2 mb-4 flex-wr">
        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_1 
          (keyup)="handleInput(null, input1, input2, $event)"
        >

        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_2 
          (keyup)="handleInput(input1, input2, input3, $event)"
        >

        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_3 
          (keyup)="handleInput(input2, input3, input4, $event)"
        >

        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_4 
          (keyup)="handleInput(input3, input4, input5, $event)"
        >

        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_5 
          (keyup)="handleInput(input4, input5, input6, $event)"
        >

        <input
          type="text" 
          class="verification-input form-control" 
          inputmode="numeric" 
          maxlength="1"
          pattern="[0-9]" 
          #input_6 
          (keyup)="handleInput(input5, input6, null, $event)"
        >

      </div>

      <button [disabled]="disableAuthenticationButton() || isLoading" (click)="validate()" class="btn btn-primary">
        <div class="d-flex gap-1">
          <div>Authenticate</div>
          <div *ngIf="isLoading"  class="small-spinner"></div> 
        </div>
      </button>
    </div>
</div>