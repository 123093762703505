
<div class="position-relative" role="button">
    <span class="add-benefits-button px-4 py-1 semi-bold" role="button">Upload Benefits</span>
    <!-- <button type="button" class="download-template px-3 py-1 f-400 ms-3">
      <a href="https://s3.us-west-2.amazonaws.com/auradocsbucket/group-medical/GM-Plan-Premium.xlsx"
          target="_blank" style="text-decoration: none;color:#FA1066">
          <img src="assets/icons/download-regular.png" class="download-icon me-2">Download Benefits Template
      </a>
  </button> -->
    <input type="file" class="file-selector" (change)="readExcel($event)" #fileClass />
</div>
