<div class="">
    <div class="agent-position">
    <div class="agent p-3 d-flex justify-content-between align-items-center">
        <div class="semi-bold add-agents-content justify-content-center">Reset Your password</div>
        <div class="" (click)="closePasswordPopup()">
            <img src="assets/icons/close.png" class="close-icon" alt="close-icon" role="button">
        </div>

    </div>
    <form class="mt-2 p-3" [formGroup]="changePasswordForm" (ngSubmit)="confirmPassword()">
        <div class="user-feild">
            <label class="light">Current password</label>
            <div class=""><input  [type]="show?'text':'password'"  class="PasswordTextbox w-100 p-1"  formControlName="current_password"  [ngClass]="{ 'is-invalid': submitted && formControls['current_password'].errors }">
                <img src="assets/icons/password-show.svg" (click)="show=!show" aria-hidden="true" *ngIf="show" class="inputbox">
                <img src="assets/icons/password-hide.svg"  (click)="show=!show" aria-hidden="true" *ngIf="!show" class="inputbox">
             
                 
                <div *ngIf="submitted && formControls['current_password'].errors" class="invalid-feedback">
                    <div class="invalid-feedback d-block" *ngIf="formControls['current_password'].errors['required']">
                      Current password is required</div>
                  </div>
            
            </div>
        </div>
        <div class="password-feild mt-3">
            <label class="light">New password</label>
            <div class=""><input [type]="confirmPasswordShow?'text':'password'" class="PasswordTextbox w-100 p-1"  formControlName="new_password"  [ngClass]="{ 'is-invalid': submitted && formControls['new_password'].errors }">
                <img src="assets/icons/password-show.svg" (click)="confirmPasswordShow=!confirmPasswordShow" aria-hidden="true" *ngIf="confirmPasswordShow" class="confirmPassword">
                <img src="assets/icons/password-hide.svg"  (click)="confirmPasswordShow=!confirmPasswordShow" aria-hidden="true" *ngIf="!confirmPasswordShow" class="confirmPassword">
             
                <div *ngIf="submitted && formControls['new_password'].errors" class="invalid-feedback">
                    <div class="invalid-feedback d-block" *ngIf="formControls['new_password'].errors['required']" >
                      New password is required</div>
                      <div class="invalid-feedback d-block" *ngIf="formControls['new_password']['errors']['minlength']">
                        Password minimum length is 5
                      </div>
                  </div>
            
            </div>
        </div>

     
        <div class="login-button-parent mt-5">
            <div class="resetPassword text-center" >
                <button class="reset-password medium px-3 py-1" type="submit">Reset Your Password</button>
            </div>
        </div>
        </form>
</div>
</div>
