import {
  Component, OnInit
} from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from './api-service/api.service';
import { API_ENDPOINTS } from './insurer-v1/constant/api.constant';
import { SessionService } from './session-service/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'group-medical';
  insurerName:any;

  constructor(private apiService:ApiService,private sessionService:SessionService) {
    this.tester();
    this.checkPath();
    console.log("Health check time - 12-JULY-2024 4.00:PM")
  }
  //import _ from "lodash";
  ngOnInit(): void {
    const pathName = location.pathname.split("/");
     if(pathName.includes('orientinsurance')){
        this.insurerName = 'orientinsurance';
        //  && !pathName.includes('reinsurer-v1')
    }else if(pathName.includes('newton') || pathName[1] == '02'){
      this.insurerName = 'newton';
  }else{
        this.insurerName = 'default';
    }
    this.sessionService.set('insurerTheming',this.insurerName)
  }

  tester() {
    let data = [
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1136,
          "benefits_name": "Alternative Medicine Co-pay",
          "benefits_headers_id": 16,
          "benefits_values_id": 42,
          "benefits_options": "10",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1135,
          "benefits_name": "Alternative Medicine Sub-limit",
          "benefits_headers_id": 15,
          "benefits_values_id": 39,
          "benefits_options": "1500",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1134,
          "benefits_name": "Optical Co-pay",
          "benefits_headers_id": 14,
          "benefits_values_id": 38,
          "benefits_options": "10",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1133,
          "benefits_name": "Optical Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 13,
          "benefits_values_id": 36,
          "benefits_options": "500",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1132,
          "benefits_name": "Dental Co-pay",
          "benefits_headers_id": 12,
          "benefits_values_id": 35,
          "benefits_options": "10",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1131,
          "benefits_name": "Dental Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 11,
          "benefits_values_id": 31,
          "benefits_options": "2000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1130,
          "benefits_name": "Medical Appliances Sub Limit",
          "benefits_headers_id": 10,
          "benefits_values_id": 27,
          "benefits_options": "5000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1129,
          "benefits_name": "Congenital Condition Sub limit",
          "benefits_headers_id": 9,
          "benefits_values_id": 25,
          "benefits_options": "50000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1128,
          "benefits_name": "Dialysis Sub limit",
          "benefits_headers_id": 8,
          "benefits_values_id": 24,
          "benefits_options": "50000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1127,
          "benefits_name": "Maternity Sub Limits",
          "benefits_headers_id": 7,
          "benefits_values_id": 20,
          "benefits_options": "20000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1126,
          "benefits_name": "OP Services Co-Pay/Dedcutible",
          "benefits_headers_id": 6,
          "benefits_values_id": 17,
          "benefits_options": "10% co-pay",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1125,
          "benefits_name": "Pharmacy Co-Pay",
          "benefits_headers_id": 5,
          "benefits_values_id": 15,
          "benefits_options": "10% co-pay",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1124,
          "benefits_name": "Pharmacy Sub Limit",
          "benefits_headers_id": 4,
          "benefits_values_id": 12,
          "benefits_options": "7500",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1123,
          "benefits_name": "Deductible for Doctor Consultation",
          "benefits_headers_id": 3,
          "benefits_values_id": 10,
          "benefits_options": "10% upto AED 25",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1122,
          "benefits_name": "Territory",
          "benefits_headers_id": 2,
          "benefits_values_id": 6,
          "benefits_options": "UAE + ISC + SEA + GCC",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 73,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5982.48,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 56,
          "benefits_table_id": 1121,
          "benefits_name": "Sum Insured",
          "benefits_headers_id": 1,
          "benefits_values_id": 1,
          "benefits_options": "1000000",
          "category_id": 73
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1152,
          "benefits_name": "Alternative Medicine Co-pay",
          "benefits_headers_id": 16,
          "benefits_values_id": 42,
          "benefits_options": "10",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1151,
          "benefits_name": "Alternative Medicine Sub-limit",
          "benefits_headers_id": 15,
          "benefits_values_id": 39,
          "benefits_options": "1500",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1150,
          "benefits_name": "Optical Co-pay",
          "benefits_headers_id": 14,
          "benefits_values_id": 38,
          "benefits_options": "10",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1149,
          "benefits_name": "Optical Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 13,
          "benefits_values_id": 36,
          "benefits_options": "500",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1148,
          "benefits_name": "Dental Co-pay",
          "benefits_headers_id": 12,
          "benefits_values_id": 35,
          "benefits_options": "10",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1147,
          "benefits_name": "Dental Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 11,
          "benefits_values_id": 31,
          "benefits_options": "2000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1146,
          "benefits_name": "Medical Appliances Sub Limit",
          "benefits_headers_id": 10,
          "benefits_values_id": 27,
          "benefits_options": "5000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1145,
          "benefits_name": "Congenital Condition Sub limit",
          "benefits_headers_id": 9,
          "benefits_values_id": 25,
          "benefits_options": "50000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1144,
          "benefits_name": "Dialysis Sub limit",
          "benefits_headers_id": 8,
          "benefits_values_id": 23,
          "benefits_options": "30000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1143,
          "benefits_name": "Maternity Sub Limits",
          "benefits_headers_id": 7,
          "benefits_values_id": 19,
          "benefits_options": "15000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1142,
          "benefits_name": "OP Services Co-Pay/Dedcutible",
          "benefits_headers_id": 6,
          "benefits_values_id": 17,
          "benefits_options": "10% co-pay",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1141,
          "benefits_name": "Pharmacy Co-Pay",
          "benefits_headers_id": 5,
          "benefits_values_id": 15,
          "benefits_options": "10% co-pay",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1140,
          "benefits_name": "Pharmacy Sub Limit",
          "benefits_headers_id": 4,
          "benefits_values_id": 12,
          "benefits_options": "7500",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1139,
          "benefits_name": "Deductible for Doctor Consultation",
          "benefits_headers_id": 3,
          "benefits_values_id": 10,
          "benefits_options": "10% upto AED 25",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1138,
          "benefits_name": "Territory",
          "benefits_headers_id": 2,
          "benefits_values_id": 6,
          "benefits_options": "UAE + ISC + SEA + GCC",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 56,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option1",
          "option_id": 0,
          "option_premium": 11672.2,
          "category_table_id": 74,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 5689.73,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 56,
          "benefits_table_id": 1137,
          "benefits_name": "Sum Insured",
          "benefits_headers_id": 1,
          "benefits_values_id": 2,
          "benefits_options": "500000",
          "category_id": 74
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1168,
          "benefits_name": "Alternative Medicine Co-pay",
          "benefits_headers_id": 16,
          "benefits_values_id": 42,
          "benefits_options": "10",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1167,
          "benefits_name": "Alternative Medicine Sub-limit",
          "benefits_headers_id": 15,
          "benefits_values_id": 39,
          "benefits_options": "1500",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1166,
          "benefits_name": "Optical Co-pay",
          "benefits_headers_id": 14,
          "benefits_values_id": 38,
          "benefits_options": "10",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1165,
          "benefits_name": "Optical Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 13,
          "benefits_values_id": 36,
          "benefits_options": "500",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1164,
          "benefits_name": "Dental Co-pay",
          "benefits_headers_id": 12,
          "benefits_values_id": 35,
          "benefits_options": "10",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1163,
          "benefits_name": "Dental Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 11,
          "benefits_values_id": 31,
          "benefits_options": "2000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1162,
          "benefits_name": "Medical Appliances Sub Limit",
          "benefits_headers_id": 10,
          "benefits_values_id": 28,
          "benefits_options": "10000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1161,
          "benefits_name": "Congenital Condition Sub limit",
          "benefits_headers_id": 9,
          "benefits_values_id": 25,
          "benefits_options": "50000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1160,
          "benefits_name": "Dialysis Sub limit",
          "benefits_headers_id": 8,
          "benefits_values_id": 23,
          "benefits_options": "30000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1159,
          "benefits_name": "Maternity Sub Limits",
          "benefits_headers_id": 7,
          "benefits_values_id": 20,
          "benefits_options": "20000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1158,
          "benefits_name": "OP Services Co-Pay/Dedcutible",
          "benefits_headers_id": 6,
          "benefits_values_id": 17,
          "benefits_options": "10% co-pay",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1157,
          "benefits_name": "Pharmacy Co-Pay",
          "benefits_headers_id": 5,
          "benefits_values_id": 15,
          "benefits_options": "10% co-pay",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1156,
          "benefits_name": "Pharmacy Sub Limit",
          "benefits_headers_id": 4,
          "benefits_values_id": 12,
          "benefits_options": "7500",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1155,
          "benefits_name": "Deductible for Doctor Consultation",
          "benefits_headers_id": 3,
          "benefits_values_id": 11,
          "benefits_options": "0% co-pay",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1154,
          "benefits_name": "Territory",
          "benefits_headers_id": 2,
          "benefits_values_id": 6,
          "benefits_options": "UAE + ISC + SEA + GCC",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 75,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6066.12,
          "category_name": "cat1",
          "category_index": 0,
          "option_quote_id": 57,
          "benefits_table_id": 1153,
          "benefits_name": "Sum Insured",
          "benefits_headers_id": 1,
          "benefits_values_id": 1,
          "benefits_options": "1000000",
          "category_id": 75
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1184,
          "benefits_name": "Alternative Medicine Co-pay",
          "benefits_headers_id": 16,
          "benefits_values_id": 42,
          "benefits_options": "10",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1183,
          "benefits_name": "Alternative Medicine Sub-limit",
          "benefits_headers_id": 15,
          "benefits_values_id": 39,
          "benefits_options": "1500",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1182,
          "benefits_name": "Optical Co-pay",
          "benefits_headers_id": 14,
          "benefits_values_id": 38,
          "benefits_options": "10",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1181,
          "benefits_name": "Optical Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 13,
          "benefits_values_id": 36,
          "benefits_options": "500",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1180,
          "benefits_name": "Dental Co-pay",
          "benefits_headers_id": 12,
          "benefits_values_id": 35,
          "benefits_options": "10",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1179,
          "benefits_name": "Dental Sub-limit (with 20% co-pay)",
          "benefits_headers_id": 11,
          "benefits_values_id": 31,
          "benefits_options": "2000",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1178,
          "benefits_name": "Medical Appliances Sub Limit",
          "benefits_headers_id": 10,
          "benefits_values_id": 28,
          "benefits_options": "10000",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1177,
          "benefits_name": "Congenital Condition Sub limit",
          "benefits_headers_id": 9,
          "benefits_values_id": 26,
          "benefits_options": "100000",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1176,
          "benefits_name": "Dialysis Sub limit",
          "benefits_headers_id": 8,
          "benefits_values_id": 24,
          "benefits_options": "50000",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1175,
          "benefits_name": "Maternity Sub Limits",
          "benefits_headers_id": 7,
          "benefits_values_id": 19,
          "benefits_options": "15000",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1174,
          "benefits_name": "OP Services Co-Pay/Dedcutible",
          "benefits_headers_id": 6,
          "benefits_values_id": 17,
          "benefits_options": "10% co-pay",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1173,
          "benefits_name": "Pharmacy Co-Pay",
          "benefits_headers_id": 5,
          "benefits_values_id": 16,
          "benefits_options": "0% co-pay",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1172,
          "benefits_name": "Pharmacy Sub Limit",
          "benefits_headers_id": 4,
          "benefits_values_id": 12,
          "benefits_options": "7500",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1171,
          "benefits_name": "Deductible for Doctor Consultation",
          "benefits_headers_id": 3,
          "benefits_values_id": 10,
          "benefits_options": "10% upto AED 25",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1170,
          "benefits_name": "Territory",
          "benefits_headers_id": 2,
          "benefits_values_id": 7,
          "benefits_options": "WW excl US/Canada",
          "category_id": 76
      },
      {
          "company_id": 70,
          "company_name": "rajan enterprises",
          "trade_license_number": "vbn6789bn4",
          "email_id": "abc@gmail.com",
          "mobile_number": "+917558163344",
          "industry": "Medical Industry",
          "client_reference_number": "C72022122206150159576",
          "generated_by": 1,
          "created_date": "2022-12-22T00:45:03.000Z",
          "modified_date": "2022-12-22T00:45:03.000Z",
          "census_id": 41,
          "employee_id": 1,
          "relations": "empolyee",
          "dob": "22-Mar-1983",
          "gender": "Male",
          "marital_status": "Married",
          "nationality": "india",
          "visa_issuance_emirate": "dubai",
          "category": "Category B",
          "premium": 4182.1,
          "quote_id": 57,
          "new_or_existing_group": "New group",
          "policy_start_date": "2022-12-04",
          "option_details": null,
          "policy_end_date": "2022-12-10",
          "distributor_commission": "5",
          "quote_options": 2,
          "no_of_categories": 2,
          "option_name": "option2",
          "option_id": 1,
          "option_premium": 12092.9,
          "category_table_id": 76,
          "emirates_id": 1,
          "emirates_name": "Abu dhabi",
          "tpa_id": 1,
          "tpa_name": "Mednet",
          "plan_id": 1,
          "plan_name": "Gold",
          "category_premium": 6026.81,
          "category_name": "cat2",
          "category_index": 1,
          "option_quote_id": 57,
          "benefits_table_id": 1169,
          "benefits_name": "Sum Insured",
          "benefits_headers_id": 1,
          "benefits_values_id": 2,
          "benefits_options": "500000",
          "category_id": 76
      }
  ]


    let variable = _.groupBy(data, "option_id")
    let option = []
    let response = Object.keys(variable).map(key => {
      let newObj = {};
      let category = _.groupBy(variable[key], 'category_table_id')
      newObj['option_name'] = variable[key][0]['option_name']
      newObj['option_id'] = variable[key][0]['option_id']
      newObj['option_premium'] = variable[key][0]['option_premium']
      const formattedCatObj = Object.keys(category).map((key, catIndex) => {
        const catData = category[key]
        let newCatObj = {}
        newCatObj['category_id'] = catData[0]['category_index']
        newCatObj['category_name'] = catData[0]['category_name']
        newCatObj['category_premium'] = catData[0]['category_premium']
        newCatObj['data'] = {
          emirates: {
            emirates_id: catData[0]['emirates_id'],
            emirates_name: catData[0]['emirates_name']
          },
          tpa: {
            tpa_id: catData[0]['tpa_id'],
            tpa_name: catData[0]['tpa_name']
          },
          plan: {
            plan_id: catData[0]['plan_id'],
            plan_name: catData[0]['plan_name']
          }
        }
        let primary_benefits=catData.map(benefitObj=>{
            let newBenefitObj={
            'benefits_headers_id':benefitObj['benefits_headers_id'],
            'benefits_values_id':benefitObj['benefits_values_id'],
            benefits_name:benefitObj['benefits_name'],
            benefits_options:benefitObj['benefits_options'],
            category_id:benefitObj['category_id']
            }
            return newBenefitObj;
          })
        newCatObj['data']['primary_benefits']=primary_benefits;
        return newCatObj
      })
      newObj['data'] = formattedCatObj;
      option.push(newObj)
    })
  }

  async checkPath(){
    const path = location.pathname.split('/');
    let reinsurerName;
    let insurerName;
    if(path.includes('login')){
        if(path.length == 3){
            reinsurerName = path[1]
        }else if(path.length == 4){
            reinsurerName = path[1];
            insurerName = path[2]
        }
    }
    let params={
        reinsurerName:reinsurerName,
        insurerName:insurerName
    }
    // let response = await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`,params)
    // console.log("--thiss.location",location.pathname,response)
  }

}
