export const API_ENDPOINTS_V2 = {
  groupApi: "group",
  emirates: "emirates",
  crnPrefix:'crn-prefix',
  tpa: "tpa",
  plan: "plan",
  minimumBasePremiumBasedOnTpa:'minimumPremium',
  insurer: "insurer",
  insurerBasedBasicDetails: "insurer-based-basic-details",
  getAllRoles: "roles",
  getRolesModules: "role-module",
  chronicMaternity: "chronic-maternity",
  users: "users",
  premium: "premium",
  getAllGroupsList:'get-all-groups-list',

  // underwriter
  groupDeclaration: "group-declaration",
  uwRules: "uw-rules",
  mafDeclaration: "MAF-declaration",
  getNationalityGroupBasedOnTPA: "nationality-group",
  nationality: "nationality",
  getNationalityUnderGroup: "nationality-under-group",
  nationalityGroup: "nationality-group",
  nationalityGroupMapping: "nationality-group-mapping",

  // Group rule
  groupRules: "group-rule",
  memberType: "member-type",
  censusMemberType: "census-member-type",
  categorizationCount: "categorization-count",
  ageEligibility: "age-eligibility",
  dependentEligibility: "dependent-eligibility",
  volumeDiscount: "volume-discount",
  getRule: "rule",

  // industry
  industryGroup: "industry-group",
  industry: "industry",
  industryUnderGroup: "industry-under-group",
  industryGroupMapping: "industry-group-mapping",

  // previous-insurer
  previousInsurerGroup: "previous-insurer-group",
  previousInsurer: "previous-insurer",
  previousInsurerUnderGroup: "previous-insurer-under-group",
  previousInsurerGroupMapping: "previous-insurer-group-mapping",

  // maternity
  maternityDiscount: "maternity-discount",

  // children
  childrenDiscount: "children-discount",

  // blacklisted
  blackListedCompanies: "blacklisted-companies",

  // Administrative-rules
  administrativeRules: "administrative-rules",

  // ceding-commission
  cedingCommission: "ceding-commission",
  insurerConfig: "insurer-config",

  // create-user
  credential: "credential",

  // Analytics
  analytics: "analytics",
  allInsurer: "all-insurer",
  report:"category-wise-report",
  optionReport:"option-wise-report",
  insurerBrokerCompany: "insurer-broker-company",
  insurerBrokerCompanyUser: "insurer-broker-company-users",

  //Benefits
  benefit: "benefits",
  benefitHeader: "benefit-header",
  benefitValue: "benefit-value",
  benefitFactor: "benefit-factor",
  benefitCopay: "benefit-copay",
  benefitHeaderCopay: "benefit-header-copay",
  optionalBenefit: "optional-benefit",
  emirateBenefitHeader: "emirates-benefit-header",

  // Broker company details
  borkerCompany: "broker-company",
  borkerAgent: "broker-agent",
  reinsurerCompany: "reinsurer-company",

  // quote
  getPreviousInsurerForQuote: "previous-insurer",
  quote: "quotes/generate",
  paymentMode: "payment-mode",
  distributorCommision: "distributor-commission",
  excelConfig: "config",
  createQuote: "quote",
  editQuote: "edit-quote",
  thirdPartyApi:"third-party-config",
  treatyDateEligibility:'eligible-treaty',

  // quote details
  quotes: "quotes",
  view: "view",
  viewQuote: "view-quotes",
  tpaDetails : "tpa-details",

  //dashboard
  dashboard: "dashboard",
  count: "count",
  customerAccepted: "customer-accepted",
  referDiscount: "refer-discount",
  notification: "notification",

  //changepassword
  changePassword: "change-password",

  // user
  user: "user",
  insurerBasedBasicDetials: "insurer-based-basic-detials",

  // document
  document: "document",
  exclusion: "exclusions",
  tpaNetwork: "tpa-networks",
  template: "templates",
  download: "download",

  // Quote Summary
  quoteSummary: "quotes/summary",
  configDocument: "config-document",
  workFlow: "workflow",
  census: "census",
  company: "company",
  revisedQuotes: "revised-quotes",
  quoteJourney: "quote-journey",
  nextFlow: "next-flow",
  lastWorkFlow: "last-workflow",
  censusBasedOnCategoryId: "census-by-category",
  ageBandCensusCategory: "ageband-census-by-category",
  ageBoundary: "age-boundary",
  getHighCensus: "highrisk-census",
  warningRules: "warning-rules",
  groupDocument : "group-document",
  groupDocumentStatus : "group-document-status",

  //chat
  chat: "chat",
  workflowHistory: "workFlow-history",
  quoteIds: "quoteIds",
  highRiskCensus: "highrisk-census",
  documents: "documents",
  referredQuote: "referred-quote",
  quoteRevision: "quote-revision",
  customerAcceptedTemplate: "templates-customer-accepted",
  customerDocuments: "customer-documents",

  // Quote-history
  history: "history",
  updateSelectedQuotes: "selection",

  //Guidelines
  guidelines:"guidelines",

  // Forgot password::
  forgotPassword:'forgot-password',

  // Terms and conditions::
  termsAndConditions:'terms-conditions',

  // Policy::

  policy:'policy',
  getAllCensusDetailsBasedOnCRN:'',
  createPolicy:'createPolicy',
  generatePolicy:'generate-policy'

};
