import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/session-service/session.service';

@Injectable({
  providedIn: 'root'
})
export class MfaGuard implements CanActivate {

  constructor(private sessionService: SessionService,
    private router: Router
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let token: string | null = this.sessionService.get('token')
    let isMfaEnabled: boolean = this.sessionService.getParsedValue('isMfaEnabled')
    let isMfaTokenExpired: boolean = this.sessionService.getParsedValue('isMfaTokenExpired')
    let reinsurerShortForm = this.sessionService.get('reinsurerShortForm');
    let insurerUrl = this.sessionService.get('insurerURL');

    if (!token || token.length <= 0) {
      this.router.navigate(['/'])
      return false
    }
    let isUserVerified = this.sessionService.getParsedValue('userVerified')

    if (state.url == '/2fa' && isMfaEnabled && isMfaTokenExpired && !isUserVerified) {
      return true
    }
    else if (state.url == '/2fv' && isMfaEnabled && !isUserVerified) {
      return true
    } else {
      this.router.navigate([`${reinsurerShortForm}/${insurerUrl}/login`])
      return false
    }

  }

}